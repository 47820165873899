<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="workflowDataLocal.title"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="workflowDataLocal.internalNote"
                outlined
                auto-grow
                :label="$t('internalNote')"
                :placeholder="$t('internalNote')"
                hide-details="auto"
                rows="4"
                maxlength="1000"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    workflowData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const workflowDataLocal = ref({})
    workflowDataLocal.value = _.cloneDeep(props.workflowData)

    const { updateWorkflowTemplate } = useActions('jobs', ['updateWorkflowTemplate'])

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateWorkflowTemplate({
        workflowId: workflowDataLocal.value.id,
        payload: workflowDataLocal.value,
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        workflowDataLocal.value = _.cloneDeep(props.workflowData)
      },
    )

    const areDataChanged = computed(() => {
      return (
        workflowDataLocal.value.title !== props.workflowData.title ||
        workflowDataLocal.value.internalNote !== props.workflowData.internalNote
      )
    })

    return {
      handleFormSubmit,

      valid,
      loading,
      bioEditForm,
      workflowDataLocal,
      areDataChanged,

      validators: {
        required,
      },
    }
  },
}
</script>
