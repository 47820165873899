<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ workflowTemplateData.title }}</span>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('title') }}:</span>
              <span class="text--secondary">{{ workflowTemplateData.title }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-1">
              <span>
                <span class="font-weight-medium me-2">{{ $t('internalNote') }}:</span>
                <span class="text--secondary">{{ workflowTemplateData.internalNote }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdBy') }}:</span>
              <span class="text--secondary">{{ recruiterText(workflowTemplateData.createdBy) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(workflowTemplateData.createdAt) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('updatedAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(workflowTemplateData.updatedAt) }}</span>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="my-2">
            <v-col cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn small color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2" size="16">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">{{
                        $t('confirmationModalUndoneQuestion')
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteWorkflowTemplateHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" outlined @click="duplicateWorkflowTemplateHandler">
                <v-icon class="me-2" size="16">{{ icons.mdiContentDuplicate }}</v-icon>
                <span v-t="'duplicate'" />
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2" size="16">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>

          <h2 v-t="'preview'" class="text-xl font-weight-semibold mb-2 mt-4" />

          <v-divider></v-divider>
          <v-stepper vertical value="0" class="my-2 custom-header stepper-custom-preview" flat non-linear>
            <template v-for="(step, index) in workflowTemplateData.stepsTemplate">
              <v-stepper-step
                :key="`${index}-step`"
                :step="index"
                color="primary"
                complete
                editable
                :edit-icon="icons.mdiCircleOutline"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">{{ index + 1 }}</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">{{ step.name }}</span>
                    <span class="text--secondary text-xs">{{ step.description }}</span>
                  </div>
                </div>
              </v-stepper-step>
              <v-stepper-content v-if="step.stepDayLimit !== null" :key="`${index}-step-content`" :step="index">
                <span class="font-weight-medium me-2">{{ $t('stepDayLimit', { postfix: ':' }) }}</span>
                <span class="text--secondary">{{ step.stepDayLimit }}</span>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-card-text>
      </v-card>

      <workflow-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :workflow-data.sync="workflowTemplateData"
      ></workflow-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiContentDuplicate, mdiCircleOutline, mdiPencilOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useState } from 'vuex-composition-helpers'

import { formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'
import WorkflowBioEdit from './WorkflowBioEdit.vue'

export default {
  components: {
    WorkflowBioEdit,
  },
  setup() {
    const { deleteWorkflowTemplate, duplicateWorkflowTemplate, fetchRecruitersChoices, recruiterText } =
      workflowTemplateUtils()

    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const { workflowTemplateData } = useState('jobs', ['workflowTemplateData'])

    const deleteWorkflowTemplateHandler = async () => {
      await deleteWorkflowTemplate(workflowTemplateData.value.id)
      router.push({ name: 'apps-workflow-templates-list' })
    }

    const duplicateWorkflowTemplateHandler = async () => {
      await duplicateWorkflowTemplate(workflowTemplateData.value.id)
      router.push({ name: 'apps-workflow-templates-list' })
    }

    onMounted(async () => {
      await fetchRecruitersChoices()
    })

    return {
      recruiterText,
      formatDateToMonthShort,
      deleteWorkflowTemplateHandler,
      duplicateWorkflowTemplateHandler,

      // statusText,

      workflowTemplateData,
      isBioDialogOpen,
      isDeleteDialogOpen,

      icons: {
        mdiTrashCanOutline,
        mdiContentDuplicate,
        mdiCircleOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
