<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title v-t="'steps'" />
      <v-card-text>
        <v-form v-model="stepsValid" @submit.prevent="handleStepsSubmit">
          <v-row>
            <v-col cols="12">
              <draggable
                v-model="localSteps"
                v-bind="dragOptions"
                :scroll-sensitivity="350"
                :force-fallback="true"
                :disabled="!stepsEdit"
                @end="onDragEnd"
                @start="onDragStart"
              >
                <div v-for="(step, index) in localSteps" :key="`Step--${index}`" class="py-3">
                  <v-card outlined class="card--border-primary my-handle">
                    <v-card-title v-if="!stepsEdit">
                      <v-row>
                        <v-col cols="8" sm="10">
                          {{ step.name }}
                        </v-col>
                        <v-col cols="4" sm="2" class="text-sm">
                          {{ $t('stepDayLimit', { postfix: ':' }) }} {{ emptyValueFormatter(step.stepDayLimit) }}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle v-if="!stepsEdit"> {{ step.description }} </v-card-subtitle>
                    <v-card-title v-else>
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="step.name"
                            :label="$t('name')"
                            :placeholder="$t('name')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :rules="[validators.required]"
                          >
                            <template #prepend>
                              <v-icon class="cursor-pointer" color="primary">{{ icons.mdiDrag }}</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="step.stepDayLimit"
                            :label="$t('stepDayLimit', { postfix: '' })"
                            :placeholder="$t('stepDayLimit', { postfix: '' })"
                            outlined
                            dense
                            type="number"
                            min="0"
                            max="365"
                            hide-details="auto"
                            :rules="[validators.minPositiveValue, validators.maxDayValueWithEmptyValue]"
                          >
                            <template #append-outer>
                              <v-tooltip top transition="scroll-y-transition" open-delay="150">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="error"
                                    x-small
                                    v-bind="attrs"
                                    @click="deleteStep(localSteps, index)"
                                    v-on="on"
                                  >
                                    <v-icon class="me-2" size="16">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                    <span v-t="'delete'" />
                                  </v-btn>
                                </template>
                                <span v-t="'deleteStep'" class="text-xs" />
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="step.description"
                            outlined
                            auto-grow
                            :label="$t('description')"
                            :placeholder="$t('description')"
                            rows="2"
                            hide-details="auto"
                            maxlength="1000"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </div>
              </draggable>
            </v-col>
            <v-col v-if="stepsEdit" cols="12">
              <v-btn v-t="'addStep'" color="primary" block @click="addStep(localSteps)" />
            </v-col>
            <v-col cols="12">
              <v-btn v-if="!stepsEdit" color="primary" @click="stepsEdit = !stepsEdit">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
              <v-btn
                v-if="stepsEdit"
                color="primary"
                type="submit"
                class="me-3"
                :loading="stepsLoading"
                :disabled="!stepsValid || !(areStepsChanged || areStepsOrderChanged) || localSteps.length === 0"
                >{{ $t('save') }}</v-btn
              >
              <v-btn v-if="stepsEdit" v-t="'discard'" outlined color="secondary" @click="restoreSteps" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiTrashCanOutline, mdiDrag, mdiPencilOutline } from '@mdi/js'
import _ from 'lodash'
import draggable from 'vuedraggable'

import { required, minPositiveValue, maxDayValueWithEmptyValue } from '@core/utils/validation'
import { emptyValueFormatter } from '@core/utils/filter'
import useDraggableOptions from '@/plugins/draggable'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'

export default {
  components: {
    draggable,
  },
  setup() {
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const { addStep, deleteStep } = workflowTemplateUtils()

    const { updateWorkflowTemplate } = useActions('jobs', ['updateWorkflowTemplate'])
    const { workflowTemplateData } = useState('jobs', ['workflowTemplateData'])

    const localSteps = ref([])
    const stepsLoading = ref(false)
    const stepsValid = ref(true)
    const stepsEdit = ref(false)
    const frozenOrders = ref([])

    localSteps.value = _.cloneDeep(workflowTemplateData.value.stepsTemplate)
    if (localSteps.value.length === 0) stepsEdit.value = true

    frozenOrders.value = Object.freeze(
      workflowTemplateData.value.stepsTemplate
        .filter((step) => Object.hasOwnProperty.bind(step)('order'))
        .map((step) => step.order),
    )

    const areStepsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(localSteps.value, workflowTemplateData.value.stepsTemplate, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(workflowTemplateData.value.stepsTemplate, localSteps.value, _.isEqual))
      )
    })

    const areStepsOrderChanged = computed(() => {
      return !_.isEqual(
        localSteps.value.filter((step) => Object.hasOwnProperty.bind(step)('order')).map((step) => step.order),
        frozenOrders.value,
      )
    })

    const handleStepsSubmit = async () => {
      if (!areStepsChanged) return

      if (!stepsValid.value) return

      stepsLoading.value = true

      localSteps.value.forEach((step, index) => {
        if (index in frozenOrders.value) {
          // eslint-disable-next-line no-param-reassign
          step.order = frozenOrders.value[index]
        } else {
          // eslint-disable-next-line no-param-reassign
          delete step.order
        }
        if (step.stepDayLimit === '') {
          // eslint-disable-next-line no-param-reassign
          step.stepDayLimit = null
        }
      })

      await updateWorkflowTemplate({
        workflowId: workflowTemplateData.value.id,
        payload: {
          ...workflowTemplateData.value,
          stepsTemplate: localSteps.value,
        },
      })

      localSteps.value = _.cloneDeep(workflowTemplateData.value.stepsTemplate)

      frozenOrders.value = Object.freeze(
        workflowTemplateData.value.stepsTemplate
          .filter((step) => Object.hasOwnProperty.bind(step)('order'))
          .map((step) => step.order),
      )

      stepsLoading.value = false
      stepsEdit.value = false
    }

    const restoreSteps = () => {
      localSteps.value = _.cloneDeep(workflowTemplateData.value.stepsTemplate)
      stepsEdit.value = false
    }

    return {
      handleStepsSubmit,
      restoreSteps,
      addStep,
      deleteStep,
      onDragStart,
      onDragEnd,
      emptyValueFormatter,

      areStepsChanged,
      areStepsOrderChanged,

      dragOptions,
      localSteps,
      stepsLoading,
      stepsValid,
      stepsEdit,

      validators: {
        required,
        minPositiveValue,
        maxDayValueWithEmptyValue,
      },

      icons: {
        mdiTrashCanOutline,
        mdiDrag,
        mdiPencilOutline,
      },
    }
  },
}
</script>
