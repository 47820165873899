<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            workflowTemplateName: workflowTemplateData.title,
          }"
        />
        <workflow-bio-panel />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <WorkflowTabSteps v-if="workflowTemplateData.id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import WorkflowBioPanel from './workflow-bio-panel/WorkflowBioPanel.vue'
import WorkflowTabSteps from './workflow-tab-steps/WorkflowTabSteps.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    WorkflowBioPanel,
    WorkflowTabSteps,
  },
  setup() {
    const { getWorkflowTemplateDetails } = useActions('jobs', ['getWorkflowTemplateDetails'])
    const { workflowTemplateData } = useState('jobs', ['workflowTemplateData'])
    const { SET_WORKFLOW_TEMPLATE_DATA } = useMutations('jobs', ['SET_WORKFLOW_TEMPLATE_DATA'])

    const fetchWorkflowData = async () => {
      await getWorkflowTemplateDetails(router.currentRoute.params.id)
    }

    onMounted(async () => {
      await fetchWorkflowData()
    })

    onUnmounted(() => {
      SET_WORKFLOW_TEMPLATE_DATA({
        stepsTemplate: [],
      })
    })

    return {
      fetchWorkflowData,
      workflowTemplateData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
